html, body {
    font-size: 9pt;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.nav-link img {
    height: 36px;
    margin-right: 10px;
}

.nav-link {
    font-family: "Open Sans";
    font-size: 15px;
}

.nav-link.active {
    font-weight: bold;
}

.navbar-brand {
    text-align: center;
    margin-bottom: 30px;
}

.bg-light {
    background-color: #80EFFF;
}

span.page-title {
    color: #979797;
    font-size: 16px;
}

div.high {
    line-height: 3;
}

.StripeElement {
    border-radius: 20px;
    border: 1px #e6e6e8 solid;
    padding: 7px 9px 7px 30px;
    line-height: 1.5;
}

.theme-dark .StripeElement {
    background-color: white;
}


.StripeElement--focus {
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
    border-color: #80bdff;
    color: #495057;
}

.StripeElement--invalid {
    border: 1px solid #ff7070;
    box-shadow: 0px 0px 5px 0px rgba(255,100,100,1);
}

nav.navbar-top-desktop .btn-success {
    padding: 3px 10px;
    font-size: 12px;
}

div.disable-all {
    z-index: 1000;
    left: 0; top: 0; right: 0; bottom: 0;
    position: fixed;
}

span.feature-icon {
    margin-right: 4px;
}

#left {
    min-height: 100%;
    width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-column {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.plusminusfield {
    padding-left: 25px;
}

.flex-column::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.switch-wrapper {
    flex-direction: column-reverse;
    display: flex;
    flex-grow: 2;
    padding-bottom: 10px;
    padding-top: 10px;
}

#right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-height: 100%;
    background-color: #f8f9ff;
}

#main {
    background-color: white;
    padding: 10pt;
    margin: 10pt;
    border-radius: 10pt;
    flex-grow: 1;
}

#bottom {
    display: flex;
    padding: 0 10pt 10pt 10pt;
    justify-content: space-between;
}

#bottom a {
    margin-left: 10pt;
}

.filter {
    padding-bottom: 20pt;
}

.table-centered td, .table-centered th {
    text-align: center!important;
}

.pager {
    text-align: center;
}







.badge {
    font-size: 90%;
}

.badge-success {
    background-color: green;
}

.badge-info {
    background-color: #17a2b8;
}

.badge-warning {
    color: black;
    background-color: #ffc107;
}

.modal-backdrop {
    opacity: 0.5;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    border-radius: 0;
}

.page-item.active .page-link{
    background-color: #6f7f8c;
    border-color: #6f7f8c;
}

.btn:disabled {
    cursor: not-allowed;
}

tr.status-active {
    background-color: #F0FFF5;
}

tr.status-disabled {
    background-color: #FFEFEF;
}

td.direction-in, th.direction-in {
    padding-right: 2px;
}

.direction-in img, .direction-out img {
    width: 20px;
}

td.direction-out, th.direction-out {
    padding-left: 2px;
}

td.direction-in, td.direction-in button {
    color: #FF6767!important;
}

td.direction-out, td.direction-out button {
    color: #26D266!important;
}

body > tr {
    display: table !important;
    background-color: white;
}

body > tr > td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.sortableHelper {
    z-index: 1100;
}

.hidden {
    display: none;
}

.actionBar {
    display: block;
    width: 100%;
    overflow: hidden!important;
}

.paginationContainer {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.paginationTitle {
    margin-right: 10pt;
    padding-top: 3pt;
}

.pagination {
    margin-bottom: 0;
}

div.DayPickerInput input {
    display: block;
    width: 100%;
    padding: .375rem .75rem .375rem 2.375rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

div.DayPickerInput input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #617c95;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(47,60,72,.25);
}

.dropdown {
    padding-left: 35pt;
}

.modal {
    min-height: calc(100vh - 210px);
}

.modal-content {
    min-height: calc(100vh - 20px);
}

.modal-footer {
    align-items: start;
}

.globalerror-backdrop {
    position: fixed;
    left: 0; top: 0; right: 0; bottom: 0;
    background-color: black;
    opacity: 0.70;
    z-index: 5000;
}

.globalerror {
    position: fixed;
    top: 200px;
    z-index: 5001;
    left: 30%; right: 30%;
    margin: auto;
}

table.didgroups tfoot {
    font-weight: bold;
}

table.didgroups btn.info {
    margin: 0;
}

table.didgroups {
    border-collapse: collapse;
    margin-top: 5pt;
}

table.didgroups {
    line-height: 1;
}

table.didgroups th {
    text-align: center;
}

table.didgroups td, table.didgroups th {
    height: auto;
    margin: 0;
}

table.didgroups td:nth-child(1n+2) {
    text-align: center
}

table.didgroups .btn-info.btn-sm {
    margin: 0;
}

table.didgroups tfoot tr {
    background-color: #ddf8ff;
    height: 49px;
}

table.didgroups tfoot td {
    color: #888;
}


table.didgroups .btn-warning {
    padding: 6px 12px;
    border-radius: 6px;
}

table.didgroups .svg-inline--fa.fa-w-14 {
    display: inline-block;
}

table.didgroups th, table.didgroups tr, table.didgroups thead, table.didgroups td, table.didgroups tr {
    border: 0px solid #f7f7f8;
    border-radius: 0;
    margin: 0;
}

span.badge.group-active {
    background-color: green;
    cursor: pointer;
}

span.badge.group-disabled {
    background-color: #dc3545;
    cursor: pointer;
}

.btn-pause {
    background-color: #dc3545!important;
}

.btn-play {
    background-color: green!important;
}

.table-sm th, .table-sm td {
    height: auto;
}

nav.nav-tabs a.nav-item {
    text-transform: uppercase;
}

.form-group.error input, .form-group.error select,
div.error input, div.error select
{
    border: 1px solid #ff7070;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255,100,100,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255,100,100,1);
    box-shadow: 0px 0px 5px 0px rgba(255,100,100,1);
}

span.balance span {
    color: #666;
    font-weight: bold;
}

.negative {
    color: #ff7058;
}

.pad-left {
    margin-left: 10px;
}

div.nav-item.static {
    color: rgba(0,0,0,.3);
    padding-right: .5rem;
    padding-left: .5rem;
    line-height: 32px;
}

.rbt-input-multi .rbt-input-wrapper {
    margin-bottom: -2px!important;
}

@media only screen and (max-width: 768px)  {
    .navbar.sidebar {
        padding: 0px;
    }
    .flex-column div {
        padding-left: 10px;
    }
    .menu div {
        margin: 0px;
        padding: 0px;

    }
}

@media only screen and (max-width: 768px)  {

    .globalerror {
        top: 300px;
        left: 10%; right: 10%;
    }

    #main {
        margin: 0;
        padding: 10px 0 10px 0;
    }

    .table td, .table th {
        padding: 3px;
    }

    .nomobile {
        display: none;
    }

}

.alert-info {
    border-radius: 20px;
    margin-top: 20px;
}

div.creditcard {
    border: 1px #e6e6e8 solid;
    border-radius: 20px;
    padding: 20px 10px 10px 20px;
    cursor: pointer;
    min-height: 145px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.creditcard {
}

div.form-group:not(.row) > label.form-label {
    padding-left: 10px;
}
