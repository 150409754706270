.form-group.row .form-label {
    text-align: right;
}

.form-check-inline {
    padding-top: 5px;
}

.left {
    text-align: left!important;
}

.right {
    text-align: right!important;
}

.center {
    text-align: center;
}

table.centered td, table.centered th {
    text-align: center;
}

.button-link {
    color: #2f3c48;
    border: none;
    text-decoration: none;
    background: none;
    padding: 0;
    margin: 0;
}

.button-link:hover, .button-link:focus, .button-link:active {
    text-decoration: underline!important;
    border: none!important;
    background: none!important;
    box-shadow: none!important;
}

.didgroup .btn {
    padding: 2px 5px 2px 5px;
}

.didgroup {
    margin-bottom: 15pt;
}
